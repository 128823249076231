html,
body {
  font-size: 100%;
}

.pg-viewer-wrapper {
  overflow-y: hidden !important;
    height: 80%;
    width: 100%;
}

.pg-viewer-wrapper .document-container {
  color: black;
  background-color: white;
}

.table-gap tr td:first-child { border-top-left-radius: 10px; border-bottom-left-radius: 10px; }
.table-gap tr td:last-child { border-top-right-radius: 10px; border-bottom-right-radius: 10px;}

.pac-container {
  z-index: 1301;
}

.ql-tooltip {
  left: 8px!important;
}

.notes {
  font-size: 0.9rem;
}

.notes > ul {
  margin-left: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
}
.notes > ul > li{
  margin-left: 16px;
  margin-bottom: 5px;
}


@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -8px, 0);
  }
}

.bouncing-loader {
  display: flex;
}

.bouncing-loader > div {
  width: 8px;
  height: 8px;
  margin: 0.6rem 0.2rem;
  background: #8385aa;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}